import CenterTitle from "../../modules/CenterTitle/index.component";
import {MainContainer} from "./MainPage.style";
import {TitleBL} from "../../ui/components/Titles/Titles.style";
import {useTonAddress, useTonConnectUI} from "@tonconnect/ui-react";
import {useState} from "react";
import {
    checkPaymentStatus,
    createPayment,
    transactionBuilder
} from "../../api/payments";

const MainPage = () => {
    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);

    const [tonConnectUI, setOptions] = useTonConnectUI();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSendTransaction = async () => {
        if (userFriendlyAddress.length !== 0) {
            setIsLoading(true);
            setError(null);
            try {
                const newPaymentResult = await createPayment(userFriendlyAddress, 1);

                const transaction = transactionBuilder(newPaymentResult)

                await tonConnectUI.sendTransaction(transaction);

                let isPayed = false;
                let amountOfRetres = 0;

                while (!isPayed) {
                    amountOfRetres++;

                    if (amountOfRetres > 100) {
                        setError(`Amount of retries over, something is wrong`);
                        isPayed = true;
                    }

                    await new Promise(resolve => setTimeout(resolve, 10000));

                    const statusPaymentResult = await checkPaymentStatus(newPaymentResult.pay_id);

                    if (parseInt(statusPaymentResult.status) > 0) {
                        setError(`Completed! Order id: ${statusPaymentResult.pay_id}`);
                        console.log(`Completed! Order id: ${statusPaymentResult.pay_id}`);
                        isPayed = true;
                        setIsLoading(false)
                    } else {
                        console.log(`Not yet, status: ${statusPaymentResult.status} Order id: ${statusPaymentResult.pay_id}`);
                    }
                }
            } catch (err) {
                setError('Failed to send transaction: ' + err.message);
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <CenterTitle aciveTab={'Home'} />
            <MainContainer>
                <TitleBL>
                    TON Payment
                </TitleBL>
                <div style={{display: 'block'}}>
                    <div>
                        User Friendly addr: {userFriendlyAddress}
                    </div>
                    <div>
                        Raw addr: {rawAddress}
                    </div>
                </div>
                <div>
                    <button onClick={handleSendTransaction} disabled={isLoading}>
                        {isLoading ? 'Sending...' : 'Send transaction'}
                    </button>
                    {error && <div style={{color: 'red'}}>{error}</div>}
                </div>
            </MainContainer>
        </>
    );
};

export default MainPage;