import './App.css';
import LayOutModule from "./modules/LayOut/index.module";
import {useDispatch, useSelector} from "react-redux";
import styled, {ThemeProvider} from "styled-components";
import {darkTheme, lightTheme, ThemeContext} from "./theming";
import {selectTheme} from "./store/theme/theme-selector";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import MainPage from "./modules/MainPage/MainPage.page";
import {TonConnectUIProvider} from "@tonconnect/ui-react";
window.Buffer = window.Buffer || require("buffer").Buffer;

const AppWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  background: ${(props) => props.theme.background.background};
  min-height: 100vh;
`

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
]);

function App() {
  const dispatch = useDispatch();

  const currentThemeText = useSelector(selectTheme);

  const currentTheme = currentThemeText === 'light' ? lightTheme : darkTheme;

  return (
      <ThemeProvider theme={currentTheme}>
        <ThemeContext.Provider value={currentTheme}>
          <TonConnectUIProvider manifestUrl='https://tondapp.airdrop-hunter.site/ton_manifest.json'>
            <AppWrapper>
              <LayOutModule>
                <RouterProvider router={router} />
              </LayOutModule>
            </AppWrapper>
          </TonConnectUIProvider>
        </ThemeContext.Provider>
      </ThemeProvider>
  );
}

export default App;
