import axios from "axios";

const apiUrl = 'https://api-dap.airdrop-hunter.site';

const getPaymentType = async () => {
    try {
        return (await axios.get(`${apiUrl}/ton/grp/`)).data.result;
    } catch (e) {
        console.error(e);
    }
}

export const createPayment = async (walFrom, paymentId) => {
    const config = {
        params: {
            id: walFrom,
            pid: paymentId
        }
    }

    try {
        const response = await axios.get(`${apiUrl}/ton/payment_create/`, config);

        console.log(response.request.responseURL)

        console.log(response.data.result)

        return response.data.result
    } catch (e) {
        console.error(e);
    }
}

export const checkPaymentStatus = async (paymentId) => {
    try {
        const response = await axios.get(`${apiUrl}/ton/payment_check/?id=${paymentId}`);

        console.log(response.request.responseURL);
        console.log(response.data)

        return response.data.result;
    } catch (e) {
        console.log(e)
    }
}

export const transactionBuilder = (newPaymentResult) => {
    return {
        validUntil: Math.floor(new Date() / 1000) + 3600,
        messages: [
            {
                address: newPaymentResult.addr_to,
                amount: '1000000',
                stateInit: newPaymentResult.state_init
            }
        ]
    }
}